import { locationId, name, title, slackAlerts } from '@agp/branches/specific/brooklyn'
import { ROSH_HASHANAH_DAY_2, YOM_KIPPUR_EREV, SUKKOT_DAY_1, SUKKOT_DAY_7 } from '@agp/constants/holidays-2024'
import { PROMO, MEMBER, VOUCHER, STANDARD } from '@agp/constants/calendar-types'
import { FACIAL, BODY_CONTOURING, LASER } from '@agp/constants/service-types'

export const LOCATION_ID = locationId
export const LOCATION_NAME = name
export const LOCATION_TITLE = title
export const COMPANY_NAME = "Next Level Aesthetics"
export const COMPANY_NAME_SHORT = "Next Level"
export const COMPANY_LOGO = "https://storage.googleapis.com/highlevel-backend.appspot.com/location/sD5WmS51Z9LWUiQSrDG4/workflow/9b0f8657-fd07-4760-b8b1-4ec7627ef2e2/893fcd74-aeb7-465b-9066-4e8d458dd226.png?alt=media&token=0b69852a-e6cd-4222-8994-17b86e07bcb5"
export const COMPANY_ADDRESS = "3190 Shore Pkwy Ground Fl, Brooklyn, NY 11235"
export const COMPANY_MAPS_LINK = "https://maps.app.goo.gl/xHtSAYGohDJeHngk9"
export const COMPANY_PHONE = "(929) 201-1971"
export const COMPANY_WHATSAPP = "https://wa.me/19292011971"
export const COMPANY_FBMSGER = "https://m.me/NjMedical"
export const REVIEW_RATING = 5
export const REVIEW_COUNT = 409
export const TIMEZONE = "America/New_York"

// external API keys and IDs
export const POSTHOG_APP_ID = "phc_xyUmvHNIBLpJwOoLqUnaVqZ81c5co6VdtrL9QEZmms8"
export const SLACK_NOTIFICATION_WEBHOOK = slackAlerts
export const FB_PIXEL = "3786664801567134"

// location-specific config
export const TREATMENT_ROOMS = 2
export const HOURS_OF_OPERATION_START = 10 // 10am
export const HOURS_OF_OPERATION_END = 19 // 7pm
export const LUNCH_HOUR = 14 // 2pm
export const APPOINTMENT_DURATION_MINS = 60
export const APPOINTMENT_BUFFER_MINS = 0
export const FUTURE_AVAILABLE_DAYS = 7
export const OPERATION_OVERRIDES = {
	[ROSH_HASHANAH_DAY_2]: { open: false },
	[YOM_KIPPUR_EREV]: {
		[PROMO]: { end: 18 },
	},
	[SUKKOT_DAY_1]: {
		[PROMO]: { open: false },
	},
	[SUKKOT_DAY_7]: {
		[PROMO]: { open: false },
	},
}

// calendar config
export const CALENDARS = [
	{
		type: PROMO,
		service: FACIAL,
		id: "vR9rXTOtcG06L3pOuges",
		name: "Medical Facial Promo",
		public: true,
	},
	{
		type: VOUCHER,
		service: FACIAL,
		id: "AvG8taMm5wwwD1a21Ksr",
		name: "Medical Facial (Voucher)",
	},
	{
		type: MEMBER,
		service: FACIAL,
		id: "VoFlnmUTASuD88uCIRzk",
		name: "Medical Facial (Members)",
	},
	{
		type: PROMO,
		service: BODY_CONTOURING,
		id: "GUbMLxrCuQllwA6GrSlv",
		name: "Body Contouring Promo",
		public: true,
	},
	{
		type: MEMBER,
		service: BODY_CONTOURING,
		id: "aHpFoBuMCSuOrFqegMcm",
		name: "Body Contouring (Members)",
	},
	{
		type: STANDARD,
		service: LASER,
		id: "p1T9n372OzczA0JYETXr",
		name: "Laser Hair Removal",
		public: true,
	},
]