import { locationId, name, title, slackAlerts } from '@agp/branches/specific/aventura'
import { PROMO, MEMBER, VOUCHER, STANDARD } from '@agp/constants/calendar-types'
import { FACIAL, BODY_CONTOURING, HAIR_RESTORATION } from '@agp/constants/service-types'

export const LOCATION_ID = locationId
export const LOCATION_NAME = name
export const LOCATION_TITLE = title
export const COMPANY_NAME = "Flawless Skin Labs"
export const COMPANY_NAME_SHORT = "Flawless"
export const COMPANY_LOGO = "https://storage.googleapis.com/highlevel-backend.appspot.com/location/sOoY8sQ81FYyjYr0RxlB/workflow/e9385442-a327-4091-a071-3c6bb81a1725/98cccc9a-43ed-4516-affc-45fa067da0f2.png?alt=media&token=45dde9e0-bcee-4050-b776-a2722786a737=media&token=0b69852a-e6cd-4222-8994-17b86e07bcb5"
export const COMPANY_ADDRESS = "18305 Biscayne Blvd Unit 217, Aventura, FL 33160"
export const COMPANY_MAPS_LINK = "https://maps.app.goo.gl/AcF263TZAX1XTcAH9"
export const COMPANY_PHONE = "(786) 550-5556"
export const COMPANY_WHATSAPP = "https://wa.me/17868346948"
export const COMPANY_FBMSGER = "https://m.me/dolcemedicalspaaventura"
export const REVIEW_RATING = 5
export const REVIEW_COUNT = 83
export const TIMEZONE = "America/New_York"

// passwords
export const MANAGER_PASSWORD = "az3$2ua81!"
export const STAFF_PASSWORD = "Dolce33160$"

// external API keys and IDs
export const POSTHOG_APP_ID = "phc_P7UDNprxVz3uJEymiiO98rS07TEYqONJgEr6Dyd0VgY"
export const SLACK_NOTIFICATION_WEBHOOK = slackAlerts
export const FB_PIXEL = "762714059041898"

// location-specific config
export const TREATMENT_ROOMS = 2
export const HOURS_OF_OPERATION_START = 10 // 10am
export const HOURS_OF_OPERATION_END = 19 // 7pm
export const LUNCH_HOUR = 14 // 2pm
export const APPOINTMENT_DURATION_MINS = 60
export const APPOINTMENT_BUFFER_MINS = 0
export const FUTURE_AVAILABLE_DAYS = 7
export const OPERATION_OVERRIDES = {}

// calendar config
export const CALENDARS = [
	{
		type: PROMO,
		service: FACIAL,
		id: "Csq3YYQOVnEV6N2KG10u",
		name: "Medical Facial Promo",
		public: true,
	},
	{
		type: VOUCHER,
		service: FACIAL,
		id: "2SOGaqeE2bCfD1KklBtM",
		name: "Medical Facial (Voucher 💞)",
	},
	{
		type: MEMBER,
		service: FACIAL,
		id: "Mr6afPyHwmM4T21JDrjD",
		name: "Medical Facial (Members 🔁)",
	},
	{
		type: PROMO,
		service: BODY_CONTOURING,
		id: "uMQ1DcPnfHIQkz0bY1jr",
		name: "Body Contouring Promo",
		public: true,
	},
	{
		type: VOUCHER,
		service: BODY_CONTOURING,
		id: "qvXpzJTzoidUwqV1qRYY",
		name: "Body Contouring (Voucher 💞)",
	},
	{
		type: MEMBER,
		service: BODY_CONTOURING,
		id: "zz6MX2QbOvdTluNyvSym",
		name: "Body Contouring (Members 🔁)",
	},
	{
		type: STANDARD,
		service: HAIR_RESTORATION,
		id: "oMyp1SwbaHwp9Jcx7nVE",
		name: "Hair Restoration",
		public: true,
	},
]