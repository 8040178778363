export const JAN_FIRST = '2024-01-01'
export const EASTER = '2024-04-20' // varies
export const MEMORIAL_DAY = '2024-05-26' // varies
export const JULY_FOURTH = '2024-07-04'
export const LABOR_DAY = '2024-09-02' // varies
export const ROSH_HASHANAH_EREV = '2024-10-02' // varies
export const ROSH_HASHANAH = '2024-10-03' // varies
export const ROSH_HASHANAH_DAY_2 = '2024-10-04' // varies
export const YOM_KIPPUR_EREV = '2024-10-11' // varies
export const YOM_KIPPUR = '2024-10-12' // varies
export const SUKKOT_EREV = '2024-10-16' // varies
export const SUKKOT_DAY_1 = '2024-10-17' // varies
export const SUKKOT_DAY_2 = '2024-10-18' // varies
export const SUKKOT_DAY_6 = '2024-10-23' // varies
export const SUKKOT_DAY_7 = '2024-10-24' // varies
export const THANKSGIVING = '2024-11-28' // varies
export const XMAS = '2024-12-25'

export const COMPANY_HOLIDAYS = [ // company-wide off holidays
	JAN_FIRST,
	MEMORIAL_DAY,
	JULY_FOURTH,
	LABOR_DAY,
	ROSH_HASHANAH,
	YOM_KIPPUR,
	THANKSGIVING,
	XMAS,
]

export const ALL_HOLIDAYS = [
	JAN_FIRST,
	EASTER,
	MEMORIAL_DAY,
	JULY_FOURTH,
	LABOR_DAY,
	ROSH_HASHANAH_EREV,
	ROSH_HASHANAH,
	ROSH_HASHANAH_DAY_2,
	YOM_KIPPUR_EREV,
	YOM_KIPPUR,
	SUKKOT_DAY_1,
	SUKKOT_DAY_2,
	SUKKOT_DAY_6,
	SUKKOT_DAY_7,
	THANKSGIVING,
	XMAS,
]
