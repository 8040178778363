export const FACIAL = 'facial'
export const BODY_CONTOURING = 'body-contouring'
export const LASER = 'laser'
export const HAIR_RESTORATION = 'hair-restoration'
export const MICRO_CHANNELING = 'micro-channeling'
export const HYDRA_FACIAL = 'hydra-facial'
export const ACNE_FACIAL = 'acne-facial'
export const BIOREPEEL = 'biorepeel'
export const OTHER = 'other'

export default [
	FACIAL,
	BODY_CONTOURING,
	LASER,
	HAIR_RESTORATION,
	MICRO_CHANNELING,
	HYDRA_FACIAL,
	ACNE_FACIAL,
	BIOREPEEL,
	OTHER
]