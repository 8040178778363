import { locationId, name, title, slackAlerts } from '@agp/branches/specific/hollywood'
import { PROMO, MEMBER, VOUCHER, STANDARD } from '@agp/constants/calendar-types'
import { FACIAL, BODY_CONTOURING, MICRO_CHANNELING, HYDRA_FACIAL, BIOREPEEL } from '@agp/constants/service-types'

export const LOCATION_ID = locationId
export const LOCATION_NAME = name
export const LOCATION_TITLE = title
export const COMPANY_NAME = "Flawless Skin Labs"
export const COMPANY_NAME_SHORT = "Flawless"
export const COMPANY_LOGO = "https://storage.googleapis.com/highlevel-backend.appspot.com/location/sOoY8sQ81FYyjYr0RxlB/workflow/e9385442-a327-4091-a071-3c6bb81a1725/98cccc9a-43ed-4516-affc-45fa067da0f2.png?alt=media&token=45dde9e0-bcee-4050-b776-a2722786a737=media&token=0b69852a-e6cd-4222-8994-17b86e07bcb5"
export const COMPANY_ADDRESS = "3325 Hollywood Blvd #305, Hollywood, FL 33021"
export const COMPANY_MAPS_LINK = "https://maps.app.goo.gl/k5dv7d19cshTS8ML7"
export const COMPANY_PHONE = "(954) 951-9039"
export const COMPANY_WHATSAPP = "https://wa.me/19549519039"
export const COMPANY_FBMSGER = "https://m.me/flawlessskinlabshollywood"
export const REVIEW_RATING = 5
export const REVIEW_COUNT = 104
export const TIMEZONE = "America/New_York"

// external API keys and IDs
export const POSTHOG_APP_ID = "phc_MAYySchLKMTBj0BT4dQYGJ9FYUYJ5rY7m417hwULhPG"
export const SLACK_NOTIFICATION_WEBHOOK = slackAlerts
export const FB_PIXEL = "1171309377428427"

// location-specific config
export const TREATMENT_ROOMS = 1
export const HOURS_OF_OPERATION_START = 10 // 10am
export const HOURS_OF_OPERATION_END = 19 // 7pm
export const LUNCH_HOUR = 13 // 1pm
export const APPOINTMENT_DURATION_MINS = 60
export const APPOINTMENT_BUFFER_MINS = 0
export const FUTURE_AVAILABLE_DAYS = 7
export const OPERATION_OVERRIDES = {}

// calendar config
export const CALENDARS = [
	{
		type: PROMO,
		service: FACIAL,
		id: "UeXYjeheC5yZIiAgfHnr",
		name: "Medical Facial Promo",
		public: true,
	},
	{
		type: VOUCHER,
		service: FACIAL,
		id: "cD7vngENH7iMT0xq2OUY",
		name: "Medical Facial (Voucher)",
	},
	{
		type: MEMBER,
		service: FACIAL,
		id: "Fm1NP4qnJ7utTk0dUIlS",
		name: "Medical Facial (Members)",
	},
	{
		type: STANDARD,
		service: MICRO_CHANNELING,
		id: "IJ7UArm5xRxPqEEbe7J1",
		name: "Micro-Channeling / Micro-Needling",
		public: true,
	},
	{
		type: STANDARD,
		service: HYDRA_FACIAL,
		id: "OLyUaGDpUHahFeOKqMnU",
		name: "Hydra Facial",
		public: true,
	},
	{
		type: STANDARD,
		service: BIOREPEEL,
		id: "5FWnCtMoekpkUkn7R7H1",
		name: "BioRePeel",
		public: true,
	},
]