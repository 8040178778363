const configResolver = (configsList, targetConfig, accessor='name')=> {
	let resolvedConfig = null
	
	const returnedResolver = ()=> {
		if (resolvedConfig) return resolvedConfig;
		
		const config = configsList.find((config)=> config[accessor] === targetConfig)

		if (!config) {
			throw new Error(`Config not found for ${targetConfig}`)
		}
		
		Object.keys(config).forEach((key)=> {
			if (key === 'name') return;
			returnedResolver[key] = config[key]
		})

		return resolvedConfig = config
	}

	return returnedResolver
}

export default configResolver