export const PROMO = 'promo'
export const MEMBER = 'follow-up'
export const VOUCHER = 'voucher'
export const STANDARD = 'standard'
export const UNKNOWN = 'unknown'

export default [
	PROMO,
	MEMBER,
	VOUCHER,
	STANDARD,
	UNKNOWN
]