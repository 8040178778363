export const AVENTURA = 'aventura'
export const MIAMI = 'miami'
export const LASOLAS = 'lasolas'
export const SCOTTSDALE = 'scottsdale'
export const TEMPE = 'tempe'
export const KNOXVILLE = 'knoxville'
export const BROOKLYN = 'brooklyn'
export const HOLLYWOOD = 'hollywood'
export const GRAYHAWK = 'grayhawk'
export const BOCARATON = 'bocaraton'

export default [
	AVENTURA,
	MIAMI,
	LASOLAS,
	SCOTTSDALE,
	TEMPE,
	KNOXVILLE,
	BROOKLYN,
	HOLLYWOOD,
	GRAYHAWK,
	BOCARATON,
]