export const MONDAY = 'Monday'
export const TUESDAY = 'Tuesday'
export const WEDNESDAY = 'Wednesday'
export const THURSDAY = 'Thursday'
export const FRIDAY = 'Friday'
export const SATURDAY = 'Saturday'
export const SUNDAY = 'Sunday'
export const ALL_DAYS = 'All Days'

export const WEEKDAY_TO_NUMBER = {
	[SUNDAY]: 0,
	[MONDAY]: 1,
	[TUESDAY]: 2,
	[WEDNESDAY]: 3,
	[THURSDAY]: 4,
	[FRIDAY]: 5,
	[SATURDAY]: 6,
}

export const NUMBER_TO_WEEKDAY = {
	0: SUNDAY,
	1: MONDAY,
	2: TUESDAY,
	3: WEDNESDAY,
	4: THURSDAY,
	5: FRIDAY,
	6: SATURDAY,
}

export const WEEKDAYS = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY]