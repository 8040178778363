import * as AVENTURA_CONFIG from './aventura'
import * as BROOKLYN_CONFIG from './brooklyn'
import * as GRAYHAWK_CONFIG from './grayhawk'
import * as HOLLYWOOD_CONFIG from './hollywood'
import * as KNOXVILLE_CONFIG from './knoxville'
import * as LASOLAS_CONFIG from './lasolas'
import * as BOCARATON_CONFIG from './bocaraton'
import * as MIAMI_CONFIG from './miami'
import * as SCOTTSDALE_CONFIG from './scottsdale'
import * as TEMPE_CONFIG from './tempe'

const array = [
	AVENTURA_CONFIG,
	BROOKLYN_CONFIG,
	GRAYHAWK_CONFIG,
	HOLLYWOOD_CONFIG,
	KNOXVILLE_CONFIG,
	LASOLAS_CONFIG,
	BOCARATON_CONFIG,
	MIAMI_CONFIG,
	SCOTTSDALE_CONFIG,
	TEMPE_CONFIG,
]

export const find = (name)=> {
	return array.find((candidate)=> candidate.name === name)
}

export default array